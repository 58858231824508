<template>
  <div id="logs">
    <!-- <router-link to="/collection">
      <el-button type="primary" icon="el-icon-plus" style="margin-bottom:30px"
        >添加产品</el-button
      >
    </router-link> -->

    <template v-if='tableData'>
      <el-table 
        v-loading="loading"
        :header-cell-style="getRowClass"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="120"> </el-table-column>

        <el-table-column prop="type" label="平台" width="120">
        </el-table-column>

       
        <el-table-column prop="title" :show-overflow-tooltip="true" label="标题" width=""> </el-table-column>

         <el-table-column :show-overflow-tooltip="true" prop="source" label="产品地址" width=""> 

            <template slot-scope="scope">
              <a :href="scope.row.source">{{scope.row.source}}</a>
             
            </template>

         </el-table-column>

        <el-table-column prop="jiajia" label="价格变动" width="120">
           <template slot-scope="scope">
               <span>{{((Number(scope.row.jiajia)-1)*100).toFixed(2)}}%</span>
            </template>
        </el-table-column>

       

        <el-table-column prop="zhuangtai" label="状态" width="120">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.zhuangtai == '采集成功,等待上架' ? 'primary' : 'warning'"
              disable-transitions
              >{{ scope.row.zhuangtai=='采集成功,等待上架'?'采集成功':scope.row.zhuangtai }}</el-tag
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          background
          v-loading="loading"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          :page-size="20"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "Logs",
  data() {
    return {
      tableData: [
       
      ],
      loading: true,
      page:0,
      total:0,
    };
  },
  methods: {
    getRowClass({ row, column, rowIndex }) {
      console.log(row, column);
      if (rowIndex === 0) {
        return "background:#F5F7FA;color:#353535;";
      } else {
        return "";
      }
    },

    change(val){
      this.page=val;
      this.getlist()
    },

    getlist(){
     
      this.axios.get(this.api.list,{
        params:{
          page:this.page,
        }
      })
      .then((res)=>{
        this.total=Number(res.count);
        if(res.data!=''){
          this.loading = false;
          this.tableData=res.data
        }else{
           this.loading = false;
           this.$message({
            message: '暂无记录',
            type: 'warning',
           
          });
        }
      })
    }
  },
  created() {
    // let loading = setTimeout(() => {
    //   clearTimeout(loading);
    //   this.loading = false;
    // }, 2000);
    this.page+=1;

    this.getlist();
  }
};
</script>